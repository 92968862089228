import { Box, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import { convertToCurrencyFormat } from '../../utils/convert-to-currency-format.js';
import { getVehicleImage, vehicleImagePropTypes } from '../../utils/get-vehicle-image/index.js';
import { getRating } from '../../utils/get-rating-provider';
import useAncapRecord from '../../hooks/use-ancap-record';
import { ExtraSmall, H5, Small } from '../type';
import { LOCALSTORAGE_KEYS, RATERS } from '../../constants';
import CardImage from '../card-image';
import CloseIcon from '../icons/close-icon';
import StarRating from '../star-rating';
import getIsZeroStarAncapRating from '../../utils/get-is-zero-star-ancap-rating';
import { STAR_PLACEMENT } from '../star-rating/constants.js';

import { RemoveLink, StyledVehicleCard } from './styles';

const removeVehicleFromCart = (vehicleId, removeFromCompareAction) => {
  if (vehicleId) {
    if (typeof window !== 'undefined' && typeof localStorage !== 'undefined') {
      try {
        localStorage.setItem(LOCALSTORAGE_KEYS.COMPARE, []);
      } catch (e) {
        // localStorage is disabled
      }
    }
  }
  removeFromCompareAction(vehicleId);
};

const MiniCartItem = ({ vehicle, removeFromCompare }) => {
  const {
    makeDescription,
    modelDescription,
    newPrice,
    priceRangeHigh,
    priceRangeLow,
    redbookCode,
    vehicleDescription,
    year,
  } = vehicle;

  const ancapTestRecordResult = useAncapRecord(vehicle);
  const { data: ancapTestRecord } = ancapTestRecordResult;
  const { selectedRating } = getRating(vehicle, ancapTestRecord);
  const { ratingYear } = ancapTestRecord;

  const image = getVehicleImage(vehicle);

  const isZeroStarAncapRating = getIsZeroStarAncapRating(vehicle);

  return (
    <StyledVehicleCard key={redbookCode}>
      <Grid container spacing={6}>
        <Grid item xs={4}>
          <CardImage src={image.src} alt={image.alt} />

          {isZeroStarAncapRating ? (
            <>
              <StarRating stars={0} ratingBy="ANCAP" placement={STAR_PLACEMENT.COMPARISON_TILE} forceZeroStars />
            </>
          ) : (
            <>
              <StarRating
                stars={selectedRating.rating}
                ratingBy={selectedRating.provider}
                placement={STAR_PLACEMENT.COMPARISON_TILE}
                testYear={ratingYear}
              />
              {selectedRating.provider === RATERS.NO_CURRENT_RATING && <ExtraSmall>No current rating</ExtraSmall>}
              {selectedRating.provider === RATERS.NO_RATING && <ExtraSmall>Not rated</ExtraSmall>}
            </>
          )}
        </Grid>
        <Grid item xs={8}>
          <RemoveLink onClick={() => removeVehicleFromCart(redbookCode, removeFromCompare)} tabIndex="0">
            <CloseIcon />
            <Small>Remove</Small>
          </RemoveLink>
          <H5 as="h2">
            {makeDescription} {modelDescription}
          </H5>
          <Box mb={3}>
            <Small>
              {year} {vehicleDescription}
            </Small>
          </Box>
          {priceRangeLow && priceRangeHigh ? (
            <Small>
              {`From $${convertToCurrencyFormat(priceRangeLow)} - $${convertToCurrencyFormat(priceRangeHigh)} `}
            </Small>
          ) : (
            <Small>{`Price Guide: $${convertToCurrencyFormat(newPrice)}`}</Small>
          )}
        </Grid>
      </Grid>
    </StyledVehicleCard>
  );
};

MiniCartItem.propTypes = {
  vehicle: PropTypes.shape({
    // TODO: update this
    ...vehicleImagePropTypes,
    ancapRating: PropTypes.number,
    makeDescription: PropTypes.string,
    modelDescription: PropTypes.string,
    name: PropTypes.string,
    newPrice: PropTypes.number,
    priceRangeHigh: PropTypes.number,
    priceRangeLow: PropTypes.number,
    redbookCode: PropTypes.string,
    ucsrRating: PropTypes.number,
    vehicleDescription: PropTypes.string,
    year: PropTypes.number,
  }).isRequired,
  removeFromCompare: PropTypes.func.isRequired,
};

export default MiniCartItem;
