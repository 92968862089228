import _ from 'lodash';

import { CATEGORY_SLUGS } from '../constants';

import logger from './logger';

/*
  Flatten the test object nested as a single array item 
  due to being a linked entry in the SuperTable column.
*/
const flattenTestResults = (testResults) =>
  _.map(testResults, ({ test, ...rest }) => ({
    ...rest,
    test: _.first(test),
  }));

const transformAncapTestRecord = (ancapTestRecordResponse) => {
  const { testResults } = ancapTestRecordResponse;
  return {
    ...ancapTestRecordResponse,
    testResults: flattenTestResults(testResults),
  };
};

const findTestResults = (test, ancapTestRecord) => {
  const testResults = _.get(ancapTestRecord, 'testResults');
  return _.filter(testResults, (result) => _.get(result, 'test.id') === test.id);
};

const categoriseAncapRecordTests = (categorisedTests, ancapTestRecord) => {
  const categorisedTestsWithResultsFromRecord = _.reduce(
    categorisedTests,
    (result, test) => {
      const matchingTestResults = findTestResults(test, ancapTestRecord);
      if (!_.isEmpty(matchingTestResults)) return [...result, ...matchingTestResults];
      return result;
    },
    []
  );
  return categorisedTestsWithResultsFromRecord;
};

const mergeVehicleAncapRecordIntoCategories = (ancapTestCategories, ancapTestRecord) => {
  return _.map(ancapTestCategories, (category) => {
    return {
      ...category,
      tests: categoriseAncapRecordTests(category.tests, ancapTestRecord),
    };
  });
};

const extractAncapTestCategories = (ancapTestCategories, ancapTestRecord) => {
  const ancapTestCategoriesWithResults = mergeVehicleAncapRecordIntoCategories(ancapTestCategories, ancapTestRecord);
  const safetyAssist = _.find(ancapTestCategoriesWithResults, { slug: CATEGORY_SLUGS.SAFETY_ASSIST });
  const adult = _.find(ancapTestCategoriesWithResults, {
    slug: CATEGORY_SLUGS.ADULT_OCCUPANT_PROTECTION,
  });
  const pedestrian = _.find(ancapTestCategoriesWithResults, {
    slug: CATEGORY_SLUGS.PEDESTRIAN_PROTECTION,
  });
  const vulnerableRoadUsers = _.find(ancapTestCategoriesWithResults, {
    slug: CATEGORY_SLUGS.VULNERABLE_ROAD_USER_PROTECTION,
  });
  const child = _.find(ancapTestCategoriesWithResults, {
    slug: CATEGORY_SLUGS.CHILD_OCCUPANT_PROTECTION,
  });

  return {
    safetyAssist,
    adult,
    pedestrian,
    vulnerableRoadUsers,
    child,
  };
};

const getAncapOverallScoreResult = (ancapTestRecord) => {
  const test = _.find(_.get(ancapTestRecord, 'testResults'), (data) => _.get(data, 'test.title') === 'Overall Score');
  return !_.isEmpty(test) ? `${test.testValue}/${test.maxValue}` : null;
};

const getAncapTestCategoryResult = (ancapTestCategory, isOldTest) => {
  const categoryResult = isOldTest
    ? _.find(_.get(ancapTestCategory, 'tests', []), ({ test }) => test.title === ancapTestCategory.title)
    : _.find(_.get(ancapTestCategory, 'tests', []), { units: 'percentage' });
  const result = _.get(categoryResult, 'testValue');
  if (_.isUndefined(result)) {
    return null;
  }
  const showPercentageSign = Number.isNaN(Number(result)) ? '' : '%';
  return isOldTest ? result : `${result}${showPercentageSign}`;
};

const getAncapCategoryChildTests = (ancapTestCategory) =>
  _.reject(_.get(ancapTestCategory, 'tests', []), { units: 'percentage' });

const getRelatedAncapTestCategory = (safetyFeatureCategory, testCategories, shouldUsePedestrianProtection) => {
  switch (safetyFeatureCategory.slug) {
    case CATEGORY_SLUGS.ACTIVE_SAFETY_FEATURES:
      return testCategories.safetyAssist;
    case CATEGORY_SLUGS.CRASH_PROTECTION_FOR_ADULTS:
      return testCategories.adult;
    case CATEGORY_SLUGS.CRASH_PROTECTION_FOR_OTHER_ROAD_USERS:
      return shouldUsePedestrianProtection ? testCategories.pedestrian : testCategories.vulnerableRoadUsers;
    case CATEGORY_SLUGS.CRASH_PROTECTION_FOR_KIDS:
      return testCategories.child;
    default:
      logger.error('Unknown safety feature category', { safetyFeatureCategory });
      return null;
  }
};

export {
  getAncapOverallScoreResult,
  getAncapCategoryChildTests,
  categoriseAncapRecordTests,
  mergeVehicleAncapRecordIntoCategories,
  getAncapTestCategoryResult,
  extractAncapTestCategories,
  getRelatedAncapTestCategory,
  transformAncapTestRecord,
};
